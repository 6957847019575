import React from 'react';
import {
  BrowserRouter as Router, // v6 Routers
  Switch, // na versão 6 é o Routes
  Route, 
  HashRouter, 
  Redirect
  //Redirect // v6 navigate?
} from 'react-router-dom'; 

import PrivateRoute from './PrivateRoutes'; // Rotas apenas para usuários logados 
import PrivateRouteAln from './PrivateRoutesAln'; // Rotas apenas para alunos logados
import PublicRoute from './PublicRoutes'; // Usado para direcionar o usuário caso esteja logado e acesse o /login

import App from '../App'; 
import TextosPrincipal from './texto/TextosPrincipal'; 
//import ProfConfig from './professores/professorConfig';
//import AlnConfig from './alunos/alnConfig';
import HomeProf from './professores/homeProf'; 
import HomeAln from './alunos/homeAln'; 
import AcessoTelas from './publicas/acessoTelas'; /* TEMP - excluir junto com texto ao finalizar integração*/ 
import Textos from './textos/homeTextos'; 
import Testes from './testes/homeTestes'; 
import Escolas from './escolas/homeEscolas'; 
import Login from './publicas/Login'; 
import CadUsu from './publicas/cadastroUsu';
import FinCadAln from './publicas/finCadAln';
//import AlnTeste from './alunos/alnTeste/homeAlnTeste'; 
import Ativacao from './publicas/login/confirmacaoEmail'; 
import RecSenha from './publicas/login/recSenha'; 
import Termos from './termos/homeTermos'; 
import Politicas from './politicas/homePoliticas';

// testes
import TesteTemp from '../../services/testes'; 

const Routes = () => {
    return (
        <HashRouter>  {/*basename="/" hashType="noslash"*/}
            <Switch>
                <Route exact path='/' component={App} /> {/* v6 não é component */}
                <Route path='/cadtextos' component={TextosPrincipal} /> 
                <Route path='/edit/:id' component={TextosPrincipal} /> {/* captura o id da url */}
                
                <PrivateRoute path='/homeprof/textos' component={Textos} /> 
                <PrivateRoute path='/homeprof/testes' component={Testes} />  
                <PrivateRoute path='/homeprof/escolas' component={Escolas} />
                <PrivateRoute path='/homeprof/termos' component={Termos} /> 
                <PrivateRoute path='/homeprof/politicas' component={Politicas} />
               {/* <PrivateRoute path='/homeprof/config' component={ProfConfig} /> --- habilitar posteriormente */}   
                <PrivateRoute path='/homeprof' component={HomeProf} />                            
                
                {/*<PrivateRouteAln path='/homealn/etpteste' component={AlnTeste} /> -- desativada
                <PrivateRouteAln path='/homealn/config' component={AlnConfig} /> --- habilitar posteriormente */}
                <PrivateRouteAln path='/homealn' component={HomeAln} /> 

                <PublicRoute path='/login' component={Login} /> 
                <Route path='/cadusu/cadaln' component={FinCadAln} /> 
                <Route path='/cadusu/:codigo' component={CadUsu} />   
                <Route path='/cadusu' component={CadUsu} />      
                <Route path='/ativacao/:tipo/:id/:token' component={Ativacao} /> 
                <Route path='/restaurasenha/:tipo/:id/:token' component={RecSenha} />
                
                 {/*<PublicRoute path='/temp/teste' component={TesteTemp} /> TEMP */}  
                {/*<Route path='/temp' component={AcessoTelas} />  TEMP */}               
                <Route exact path='*' component={App} /> {/* v6 não é component */}          
            </Switch>
        </HashRouter>
    );
};

export default Routes;