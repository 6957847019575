import React, {  useState, useEffect } from 'react'; 

import classesApp from '../../App.module.css'; 

import api from '../../../services/api'; 
import { dados } from '../../../services/auth'; 

import Header from '../professores/headerHomeProf';
import MenuTeste from './testesPesquisa'; 
import TestesLista from './testesLista'; 
import TestesCadastro from './testesCadastro'; 
import TestesFixLac from './testesFixPlvDefLac'; 
import TestesEscRevLac from './testeEscolhaRevLac'; 
import TestesVisualiza from './testesVisualizar'; 
import TesteDados from './testeDados'; 
import Textos from '../textos/homeTextos'; 


function Testes() { 
    const [apresentacao, setApresentacao] = useState(0); 
    const [txtSelTeste, setTxtSelTeste] = useState([]); /* 
    * ao cadatrar e mostrar um teste, passa o id e o título do texto selecionado para o teste
    * quando transforma o texto em labels armazena o array com as labels representado cada palavra ou 
    * sinal - usado na tela de cadastro de teste e para mostrar visualização de teste
    */
    const [testes, setTestes] = useState([]); // testes apresentados na pesquisa
    const [vlrPesq, setVlrPesq] = useState(''); // texto do conteúdo a ser pesquisa
    const [tpPesq, setTpPesq] = useState('id'); // tipo de pesquisa realizada
    const [tesSel, setTesSel] = useState([]); // texto selecionado para o teste
    const [tesTemp, setTesTemp] = useState([]); // valores temporários para o cadastro de testes
    const [nLac, setNlac] = useState(0); // número de lacunas no texto do teste
    const [plvAprs, setPlvAprs] = useState([]); // tipos de itens do arrTxt 
    const [respostas, setRespostas] = useState([]); // armazena respostas no array 
    const [txtInp, setTxtInp] = useState(); // array gerado map para renderização de inputs 
    const [idTes, setIdTes] = useState(0); // id do teste selecionado ou cadastrado     

    useEffect(() => {
        window.scrollTo(0, 0); // usado para posicionar no início da tela ao carregar esta página
    }, []);

    function renderiza(op) {
        setApresentacao(op);
    } 

    function carregarTxt(txtSel) {
        setTxtSelTeste(txtSel);
    }

    async function listaTestes() {        
        let response = [];
        if (tpPesq === 'id') {             
            try { 
                if (dados.acesso === 'dende') {
                    response = await api.get('/testes?identificacao=' + vlrPesq);
                }  else {
                    response = await api.get('/testes?usu_id='  + dados.id + '&identificacao=' + vlrPesq); 
                }                             
                setTestes(response.data);             
            } catch (error) {
                alert('Erro ao listar dados: ' + error);
                setTestes([]);
            }
        } else {
            try {
                if (dados.acesso === 'dende') {
                    response = await api.get('/testes?apresp=' + vlrPesq); 
                } else {
                    response = await api.get('/testes?usu_id='  + dados.id + '&apresp=' + vlrPesq); 
                }
                setTestes(response.data);             
            } catch (error) {
                alert('Erro ao listar dados: ' + error);
                setTestes([]);
            }
        }
    }

    function tesPesquisa(vlr) {
        setVlrPesq(vlr); 
    } 

    function defPesquisa(vlr) {
        setTpPesq(vlr);
    } 

    function defTes(vlr) {
        setTesSel(vlr);
    } 

    async function exibeTes(id) {
        try { 
            let temp = id;
            defineIdTes(temp);
            const response = await api.get('/testes/teste/' + id);
            defTes(response.data); 
        } catch (error) {
            alert('Erro ao listar dados: ' + error);
            defTes([]);
        }
        if (apresentacao === 0 || apresentacao === 2 ) {
            renderiza(1); 
        } else {
            renderiza(2); 
        }
    }

    function defineDdTemp(dados) {
        setTesTemp(dados);
    } 

    function defineNlac(n) {
        setNlac(n);
    } 

    function defineApresPlv(arr) { 
        setPlvAprs(arr);
    } 

    // armazena respostas
    function gravaResp(e) {
        let arrResp = respostas; 
        arrResp[e.target.id] = e.target.value;
        setRespostas(arrResp); 
        //console.log(arrResp);
    } 

    function defineInputs(arr) { 
        setTxtInp(arr);
    } 

    function defineIdTes(id) {
        setIdTes(id);
    } 

    return (
        <div className={classesApp.centraliza}>            
            <Header />
            <div className={classesApp.home}>                                 
                {/* inicia com listagem dos textos com paginação */}                  
                {apresentacao !== 8 && (
                    <>
                        <h1>Testes</h1>                         
                        <MenuTeste renderiza={renderiza} apresentacao={apresentacao} vlrPesq={vlrPesq} tesPesquisa={tesPesquisa} listaTestes={listaTestes} defPesquisa={defPesquisa} tpPesq={tpPesq} defineDdTemp={defineDdTemp} carregarTxt={carregarTxt} /> 
                    </>                    
                )}                 

                {apresentacao <= 2 && (
                    <TestesLista renderiza={renderiza} testes={testes} listaTestes={listaTestes} exibeTes={exibeTes} defineIdTes={defineIdTes} />
                )} 
                {apresentacao === 1 && (
                    <TestesCadastro renderiza={renderiza} apresentacao={apresentacao} txtSelTeste={txtSelTeste} carregarTxt={carregarTxt} tesSel={tesSel} defineDdTemp={defineDdTemp} tesTemp={tesTemp} />
                )} 

                {apresentacao === 2 && (
                    <TestesCadastro renderiza={renderiza} apresentacao={apresentacao} txtSelTeste={txtSelTeste} carregarTxt={carregarTxt} tesSel={tesSel} defineDdTemp={defineDdTemp} tesTemp={tesTemp} />
                )} 

                {apresentacao === 3 && (
                    <TestesCadastro renderiza={renderiza} apresentacao={apresentacao} txtSelTeste={txtSelTeste} carregarTxt={carregarTxt} tesSel={tesSel} defineDdTemp={defineDdTemp} tesTemp={tesTemp} defineIdTes={defineIdTes} />
                )}

                {apresentacao === 4 && (
                    <TestesFixLac renderiza={renderiza} txtSelTeste={txtSelTeste} defineNlac={defineNlac} defineApresPlv={defineApresPlv} />
                )} 
                {apresentacao === 5 && (
                    <TestesEscRevLac renderiza={renderiza} nLac={nLac} plvAprs={plvAprs} txtSelTeste={txtSelTeste} defineApresPlv={defineApresPlv} gravaResp={gravaResp} defineInputs={defineInputs} txtInp={txtInp} idTes={idTes} />
                )}
                {apresentacao === 6 && (
                    <TestesVisualiza renderiza={renderiza} txtSelTeste={txtSelTeste} idTes={idTes} usuario={true} /> // define usuário que criou visualizando
                )}
                {apresentacao === 7 && (
                    <TesteDados renderiza={renderiza} idTes={idTes} />
                )}     
                {apresentacao === 8 && (
                    <Textos teste={true} apresentacaoTes={renderiza} carregarTxt={carregarTxt} />
                )}          
            </div>              
        </div>        
    );
}
  
export default Testes;