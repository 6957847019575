import React from 'react';

import CabecalhoGeral from './pages/cabecalhos/headerHome'; 
import Home from './pages/publicas/Home'; 
import Contato from './pages/publicas/Contato'; 
import Sobre from './pages/publicas/Sobre'; 

function App() {
  return (  
    <div>
      <CabecalhoGeral />      
      <Home />  
      <Sobre /> 
      <Contato />
    </div>    
  );
}

export default App;
