import React from 'react'; 
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"; 
// import { Rings } from  'react-loader-spinner'

function Carregando() {
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '2rem'}}>
            {/* <Rings color="#320b86" height={'8rem'} width={'8rem'} />  */}
            <h2>Carregando dados...</h2>
        </div>      
    );
}
  
export default Carregando;