import React, { useEffect } from 'react';

import classesApp from '../../App.module.css'; 

import pad from '../../../services/pad';

import { MdContentCopy, MdOutlineBrokenImage } from "react-icons/md"; 

function TestesLista( { renderiza, testes, listaTestes, exibeTes, defineIdTes } ) {  

    useEffect(() => {
        listaTestes();
    }, []); 

    function apresentaData(dtBd) {
        let data = new Date(dtBd); 
        const dt =  pad(data.getDate()) + '/' + pad(data.getUTCMonth() + 1) + '/' + data.getFullYear(); 
        return dt;
    } 

    function tipoTeste(tpDb) {
        switch (tpDb) {
            case 0:
                return 'Bloqueado';                        
            case 1:
                return 'Liberado';                        
            default:
                return 'Em desenvolvimeno';
        }
    }

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText('Insira o código a seguir para acessar o teste: ' + copyMe);
            //alert('Código copiado');
        } catch (err) {
            alert('Falha ao copiar!');
        }
    };

    if (testes[0]) {
        return (
            <>                
                <div className={classesApp.bordaTable}>
                    <table>
                        <thead>
                            <tr>
                                <th className={classesApp.ttTable}>Data</th>
                                <th className={classesApp.ttTable}>Identificação</th>   
                                <th className={classesApp.ttTable}>Situação</th>
                                <th className={classesApp.ttTable}>Código</th>   
                                <th className={classesApp.ttTable}>Copiar</th>                     
                                <th className={classesApp.ttTable}>Resultados</th>
                            </tr>
                        </thead>  
                        <tbody>
                            {
                                testes.map(teste => (
                                    <tr key={teste.tes_id}>
                                        <th onClick={() => {
                                            exibeTes(teste.tes_id); 
                                        }}>{apresentaData(teste.tes_dt_criacao)}</th>
                                        <th onClick={() => {
                                            exibeTes(teste.tes_id); 
                                        }}>{teste.tes_identificacao}</th>
                                        <th onClick={() => {
                                            exibeTes(teste.tes_id); 
                                        }}>{tipoTeste(teste.tes_situacao)}</th>
                                        <th onClick={() => exibeTes}>{teste.tes_cod_acesso}</th>
                                        <th><MdContentCopy size={'2rem'} className={classesApp.iconeTable} onClick={() => copyToClipBoard(teste.tes_cod_acesso)} /></th>
                                        <th><MdOutlineBrokenImage size={'2.5rem'} className={classesApp.iconeTable} onClick={() => { 
                                            teste.tes_situacao == 3 ? alert('Este teste ainda não está pronto') : defineIdTes(teste.tes_id);
                                            if (teste.tes_situacao !== 3) {
                                                renderiza(7);
                                            } 
                                        }} /></th>
                                    </tr>
                                ))
                            }                                              
                        </tbody>  
                    </table>                
                </div>                  
            </>     
        );
    } else {
        return (
            <div className={classesApp.bordaTable} style={{display: 'flex', alignItems: 'center', padding: '1rem', color: 'coral', fontWeight: 'bold', fontSize: '1.8rem'}}>            
                Nenhum resultado foi encontrado!
            </div>
        );
    }
    
}
  
export default TestesLista;