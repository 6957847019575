import React, { useState, useEffect } from 'react'; 

import classesApp from '../../App.module.css';

import api from '../../../services/api';

function TestesEscRevLac( { renderiza, nLac, plvAprs, txtSelTeste, defineApresPlv, gravaResp, defineInputs, txtInp, idTes } ) { 

    let arrTpPlv = []; // array temporário para controle de envio de dados para plvExc 

    useEffect(() => {
        // 2ª função chamada paraa separar os pontos e as palavras                   
        lacunaTexto(nLac);
    }, []); 
    

    // tamanhos para o input com a quantidade de caracteres representada no array
    const tam30carc = [0.00, 2.20, 3.80, 5.20, 6.50, 8.20, 9.40, 10.80, 12.40, 13.70, 15.10, 16.50, 17.70, 18.80, 20.50, 21.50, 22.90, 24.10, 25.50, 27.00, 28.30, 29.80, 31.00, 32.80, 34.00, 35.20, 36.70, 37.90, 39.30, 40.70, 42.20];

    /*
     * IMPORTÂNTE, READONLY NO INPUT SOMENTE NO MOMENTO EM QUE O PROFESSOR ESTÁ GERANDO ATIVIDADE 
     */

    // 5ª função gerar input n palavra
    function lacunaTexto(n) {
        let plvTipoTemp = plvAprs; // var temporária para atualizar o tipo da palavra no array
        // !!! - controle para identificar o 5º elemento (POSSÍVEL PERMITIR QUE O USUÁRIO ESCOLHA A QUANTIDADE DE INTERVALO! - IMPLEMENTAR - PARA ISSO CRIAR UMA VARIÁVEL QUE RECECBA ESTE INTERVALO QUE POR ENQUANTO É FIXO)
        let controle = 0;

        plvTipoTemp.forEach((valor, i) => {
            // se o tipo for referente a uma palavra que pode se tornar lacuna incrementa o controle
            if (valor === 'plnf' || valor === 'pln') {
                controle++;
            }
            // se for a quinta palavra que faz parte da contagem muda seu tipo para lacuna
            if (controle === n) {
                // distinção entre palavra que está no final ou no meio de um paragráfo
                if (valor === 'plnf') {
                    plvTipoTemp[i] = 'lacf';
                } 
                if (valor === 'pln') {
                    plvTipoTemp[i] = 'lac';
                }
                controle = 0; // reseta o controle para começar a contagem novamente
            }
        });

        // prepara nova renderização, agora com inputs nos locais de acordo com o nº passado, respeitando a exclusão de palavras
        let codlbl;
        let arrlab = txtSelTeste.map((vlr, i) => {
            codlbl = geraAtividade(i, vlr, plvTipoTemp[i]);
            return codlbl;
        });

        // atualiza valores para exibição
        arrTpPlv = plvTipoTemp;
        defineApresPlv(arrTpPlv);
        defineInputs(arrlab);
    }

    // 6ª função usada para retornar um JSX inputs
    function geraAtividade(chave, valor, tipo) {
        // tipos: pln (palavra n excluida para input), plnf (plv n exc p input final frase),
        // pls (plv excluída de input), plsf (plv exc p input final fras), br, sat, sdp
        // lacf (lacuna no final), lac (lacuna) 
        //console.log(plvExc); - usado para gerar valores da tabela 
        if (tipo === 'br') {
            return <br key={chave} />;
        }

        if (tipo === 'pln') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)} --> excluídos evento programado, das palavras
                    //className="txt_lbl_n" 
                    onClick={() => escolheLac(chave)} 
                    className={classesApp.txt_lbl_n}
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plnf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    //className="txt_lbl_n" 
                    onClick={() => escolheLac(chave)} 
                    className={classesApp.txt_lbl_n}
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'pls') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    //className="txt_lbl_n" /
                    className={classesApp.txt_lbl_n} 
                    onClick={() => escolheLac(chave)} 
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'plsf') {
            return (
                <label
                    key={chave}
                    value={valor}
                    //onClick={() => excPlv(chave)}
                    //className="txt_lbl_n" 
                    className={classesApp.txt_lbl_n} 
                    onClick={() => escolheLac(chave)} 
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'sat') {
            return (
                <label 
                    key={chave} 
                    value={valor} 
                    // className="txt_lbl_n"
                    className={classesApp.txt_lbl_n}
                >
                    {valor}
                </label>
            );
        }

        if (tipo === 'sdp') {
            return (
                <label 
                    key={chave} 
                    value={valor} 
                    //className="txt_lbl_n"
                    className={classesApp.txt_lbl_n}
                >
                    {valor + ' '}
                </label>
            );
        }

        if (tipo === 'lacf') {
            return (
                <span key={chave + '_' + valor + 'spn'} onClick={() => escolheLac(chave)} >
                    <input 
                        key={chave} 
                        value={valor}
                        type="text" 
                        disabled 
                        style={{ 
                            width: valor.length === 0 ? '0rem' + 'rem' : tam30carc[valor.length] + 'rem', 
                            display: valor.length === 0 ? 'none' : 'inline', pointerEvents: 'none'
                            //width: valor.length * 15, // fui testando multiplicadores para encontrar um tamanho que se ajustasse a  fonte                          
                        }} 
                        
                        id={chave}        
                        // onClick={() => escolheLac(chave)}             
                        //onChange={(e) => gravaResp(e)}                     
                    />
                </span>
                
            );
        }

        if (tipo === 'lac') {
            return (
                /* 
                    coloquei lacunas de meio de paragráfo gerando um input e um label com espaço em um span para garantir o 
                    espaço entre o input e a próxima palavra, no anterior não precisa, porque o próximo caractere 
                    será uma pontuação
                */
                <span key={chave + '_' + valor + 'spn'} onClick={() => escolheLac(chave)} >
                    <input 
                        key={chave} 
                        value={valor}
                        type="text" 
                        disabled 
                        style={{ 
                            width: valor.length === 0 ? '0rem' + 'rem' : tam30carc[valor.length] + 'rem', 
                            display: valor.length === 0 ? 'none' : 'inline', pointerEvents: 'none'
                            //width: valor.length * 15,
                        }} 
                        id={chave} 
                        // onClick={() => escolheLac(chave)}                        
                        // onChange={(e) => gravaResp(e)}                         
                    />
                    <label key={chave + '_' + valor + 'lbl'} className='txt_lbl_espaco'> </label>
                </span>
            );
        }
    } 

    // 7ª função inserir lacunas no texto
    function escolheLac(chave) {
        let plvEscolheTemp = arrTpPlv; // array tipo palavra será atualizado, por isso criado um temporário
        // mudar valor ao clicar, com base no valor anterior o atual é invertido
        if (plvEscolheTemp[chave] === 'plnf') {
            plvEscolheTemp[chave] = 'lacf';
        } else if (plvEscolheTemp[chave] === 'pln') {
            plvEscolheTemp[chave] = 'lac';
        } else if (plvEscolheTemp[chave] === 'plsf') {
            plvEscolheTemp[chave] = 'lacf';
        } else if (plvEscolheTemp[chave] === 'pls') {
            plvEscolheTemp[chave] = 'lac';
        } else if (plvEscolheTemp[chave] === 'lacf') { 
            plvEscolheTemp[chave] = 'plnf'; 
        } else if (plvEscolheTemp[chave] === 'lac') {
            plvEscolheTemp[chave] = 'pln'; 
        }

        // renderizar
        let codlbl;
        // percorre todo array temporário de tipo de palavras para gerar uma visualização atualizada, destacando o input gerado
        let arrlab = txtSelTeste.map((vlr, i) => {
            codlbl = geraAtividade(i, vlr, plvEscolheTemp[i]);
            return codlbl;
        });

        // atualiza os valores da lista de tipos e o que será renderizado como labels
        arrTpPlv = plvEscolheTemp;
        defineApresPlv(arrTpPlv);
        defineInputs(arrlab);
    } 

    async function lacunasBd() {
        const dados = {
            plvAprs, idTes
        }
        
        try {
            const response = await api.post('/txtdados', dados); 
            alert("Texto de " + response.data + " palavras definido para o teste com sucesso!");
        } catch (err) {
            alert("Erro no cadastro, tente novamente." + "\n" + err);
        }
        
        renderiza(6);
    }

    return (
        <div className={classesApp.centraliza}>            
            <h1>Definir ou revisar lacunas</h1> 
            <p>Clique nas palavras que deseja adicionar ou remover lacuna</p>   
            <div className={classesApp.textoTeste}>
                {txtInp}
            </div>
            <div className={classesApp.containerBtnAcoes}>
                <label className={classesApp.btnAcoes} onClick={() => lacunasBd()}>Finalizar definição</label>
            </div>                     
        </div>        
    );
}
  
export default TestesEscRevLac;